import React, { Component } from 'react';
import { Link } from "react-router-dom";

import logo_red from '../images/logo_red.svg';
import logo_settings from '../images/logo_settings.svg';
import logo_feedback from '../images/logo_feedback_blue.png';

import isMobile from '../isMobile.js';
import Feedback from '../components/Feedback';
import Popup from 'reactjs-popup';


class Header extends Component {

  constructor(props) {

    super(props);

    this.popup_ref = React.createRef();
    this.state = {

      token: localStorage.getItem("token"),
      home: this.props.home,
      section: this.props.section,
      settings: this.props.settings,
      welcome: this.props.welcome,
      teaser: this.props.teaser,
      card_activity: this.props.card_activity,
      is_medium_size: false

    }
    this.getConfigButton = this.getConfigButton.bind(this)
    this.getWelcomeButton = this.getWelcomeButton.bind(this)
    this.getTeaserButton = this.getTeaserButton.bind(this)
    this.getAppNagger = this.getAppNagger.bind(this)
    this.getFeedbackButton = this.getFeedbackButton.bind(this)

  }

  componentDidMount(){
    
    this.setState({
      is_medium_size: window.matchMedia('(max-width: 960px)').matches
    })

    const handler = e => this.setState({is_medium_size: e.matches});
    window.matchMedia('(max-width: 960px)').addEventListener('change', handler); 
  }

  getAppNagger(){
    if (isMobile) {
      return (<Link className="settings_wrapper" to="/map"><img className="settings_logo" src={logo_red} alt="Logo Settings" /></Link>)
    }
    return
  }

  getConfigButton() {

    if (this.state.token && !this.state.settings) {
      return (<Link className="settings_wrapper" to="/settings/" ><img className="settings_logo" src={logo_settings} alt="Logo Settings" /></Link>)
    }

  }

  getWelcomeButton() {
    if (this.state.welcome) {
      return (<Link className="welcome_wrapper" to="/map/" ><p className="welcome_label">Omitir</p></Link>)
    }

  }

  getTeaserButton() {
    if (this.state.teaser) {
      return (<Link className="welcome_wrapper" to={"/" + this.state.section + "/" } ><p className="welcome_label">Omitir</p></Link>)
    }

  }

  getFeedbackButton(){
    if (this.state.card_activity && this.state.is_medium_size){
      return(
            <Popup
              modal
              trigger={<Link className="settings_wrapper" to="#" ><img className="settings_logo" src={logo_feedback} alt="Logo Settings" /></Link>}
              ref={this.popup_ref}

              >
                {close => (
                  <div className="feedback_modal fadeIn">
                    <button className="close_feedback" onClick={close}>
                      &times;
                    </button>
                    <Feedback
                      parent_ref={this.popup_ref}
                    >
                    </Feedback>

                  </div>
                )}
            </Popup>
          )
    }
  }

  render() {

    // alert(navigator.userAgent)
    const redirect = this.state.home ? "/" : (this.state.token ? "/map/" : "/")
    return (

      <header className="header">

        <div className="content">

          <section className="section section_left">
            <Link className="logo_wrapper" to={redirect}><img className="logo" src={logo_red} alt="Logo White" /></Link>
          </section>

          <section className="section section_right">
            {this.getTeaserButton()}
            {this.getWelcomeButton()}
            {this.getFeedbackButton()}
            {this.getConfigButton()}
          </section>

        </div>

      </header>

    );

  }

}

export default Header;
