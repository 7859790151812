import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Redirect, Link } from "react-router-dom";

import { getToken } from '../services/ServerInterface';

import Footer from '../components/Footer';

import etraining from '../images/new_logo.png';
import appStore from '../images/app_store.png';
import googlePlay from '../images/google_play.png';
import login_right_art from '../images/login_right_art.png';
import appGallery from '../images/appgallery.png';
import pcSystem from '../images/pcSystem.png';

class Login extends Component {

  constructor (props) {
    super(props)

    this.state = {
      username: "",
      password: "",
      redirect: localStorage.getItem("token"),
    }

    this.intro = this.intro.bind(this)
    this.onChange = this.onChange.bind(this)
    this.recoverAccount = this.recoverAccount.bind(this)
 
  }

  onChange (event) {
    this.setState({[event.target.name]: event.target.value})
  }

  recoverAccount (event) {
    event.preventDefault()
  }

  intro (event) {
    event.preventDefault();

    const serverResponse = document.getElementById('server_response')

    if (this.state.username && this.state.password) {

      serverResponse.innerHTML = "Iniciando sesion ...";

      let auth = this.state.username + ":" + this.state.password
      let encoded = btoa(auth)

      let credentials = {
          payload: encoded
      }

      getToken(credentials).then ((response) => {

        if (response.ok) {

          let body = response.body

          localStorage.setItem("token", body.token);
          localStorage.setItem("user", JSON.stringify(body.user));
          localStorage.setItem("profile", JSON.stringify(body.profile));

          this.setState({ redirect: true })

        } else if (response.status === 401) {
          serverResponse.innerHTML = "Usuario y/o contraseña incorrectos.";
        } else {
          serverResponse.innerHTML = response.status + ": " + response.statusText;
        }

      })

    } else {
      serverResponse.innerHTML = "Ingrese su usuario y contraseña.";
    }

  }

  render () {

    if (this.state.redirect) {
      return (<Redirect to={"/map/"}/>);
    }

    return (
      <React.Fragment>
        <div className="intro">
          <div className="intro_content">
            <div className="intro_center">
              <div className="intro_logo_wrapper">  
                <img className="intro_logo" src={etraining} alt="logo" />
              </div>
              <div className="intro_form">
                <div className="intro_button_wrapper" data-multiline={true} data-tip='¿Ya contrataste? Ingresa aquí y aprende a gestionar tu solución.'>
                  <Link className="intro_button" to="/login/">Acceso a Cliente y Afiliado</Link>
                </div>
                <div className="intro_button_wrapper" data-tip={'Conoce el mundo Edenred y contrata YA, no esperes más.'}>
                  <Link className="intro_button" to="/guest/">Acceso Invitado</Link>
                </div>
              </div>
              <div>
                <h4 className='intro_apps_title'>Disponible en:</h4>
                <div className='intro_apps'>
                  <a href='.'><img src={pcSystem} alt="Play Store" className="intro_pcSystem"/></a>
                  <a href='https://appgallery.cloud.huawei.com/ag/n/app/C103523897?locale=es_US&source=appshare&subsource=C103523897'><img src={appGallery} alt="Play Store" className="intro_appGallery"/></a>
                  <a href='https://play.google.com/store/apps/details?id=mx.edenred.training' target='_blank' rel='noopener noreferrer'><img src={googlePlay} alt="Play Store" className='intro_google_play'/></a>
                  <a href='https://apps.apple.com/mx/app/edenred-training/id1539219655' target='_blank' rel='noopener noreferrer'><img src={appStore} alt="App Store" className='intro_app_store' /></a>
                  </div>
              </div>
            </div>
            <div className="intro_right">
              <img className="intro_image right" src={login_right_art} alt="right" />
            </div>
          </div>
          <Footer/>
        </div>
        <ReactTooltip />
      </React.Fragment>
    );
  }
}

export default Login;
