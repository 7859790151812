import React from 'react';
import { Redirect } from 'react-router-dom';

class Logout extends React.Component {

    render() {

    	let multipart = localStorage.getItem("multipart")
      
      let despensas_pop = localStorage.getItem("despensas_pop_state");
      let ticket_pop = localStorage.getItem("ticketcar_pop_state");

      localStorage.clear()

      localStorage.setItem("despensas_pop_state", despensas_pop);
      localStorage.setItem("ticketcar_pop_state", ticket_pop);


      if (multipart != null) {
      	localStorage.setItem("multipart", multipart)
      }

      return (<Redirect to={""}/>)

    }
    
}

export default Logout;