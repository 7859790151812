/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Popup from "reactjs-popup";

// import client_splash from '../images/despensas_splash_modal.svg'
import client_splash from "../images/despensas_splash_modal.png";

//import despensas_main_img from '../images/despensas_main_img.png';
import despensas_main_img from "../images/image_001.png";
import despensas_logo from "../images/logo_1.png";

import { useIntercom } from "react-use-intercom";

class Despensas extends React.Component {
  constructor(props) {
    super(props);
    this.getPopstate = this.getPopstate.bind(this);
  }

  getPopstate() {
    // return true;
    var pop_state = localStorage.getItem("despensas_pop_state");

    if (pop_state === null || isNaN(pop_state) || pop_state === "NaN") {
      localStorage.setItem("despensas_pop_state", 1);
      return true;
    }

    pop_state = parseInt(pop_state);
    if (pop_state >= 3) {
      console.log("skipping pop for despensas");
      return false;
    }

    pop_state = parseInt(pop_state) + 1;
    localStorage.setItem("despensas_pop_state", pop_state);

    return true;
  }
  render() {
    return (
      <div className="section_content_despensas">
        <div
          style={{
            display: "flex",
            borderRadius: "0 180px 0 0",
            backgroundColor: "white",
            maxWidth: '98%',
            padding: '32px'
          }}
        >
          <div className="section_splash_image_wrapper">
            <div style={{ overflow: "hidden", position: 'relative', left: '-130px' }}>
              <img
                width={"100%"}
                src={despensas_main_img}
                alt="Despensas Splash"
              ></img>
            </div>
          </div>
          <div className="section_form_container">
            <div className="section_right_wrapper">
              <div className="section_image_wrapper">
                <img
                  width={'300px'}
                  className="center"
                  src={despensas_logo}
                  alt="Despensas logo"
                ></img>
              </div>
              <div className="section_form">
                <div className="section_button_wrapper">
                  <Link
                    className="section_button"
                    to={"/" + this.props.section + "/card_management/"}
                  >
                    Autogestión de Tarjetas
                  </Link>
                </div>

                <div className="section_button_wrapper">
                  <Link
                    className="section_button"
                    to={"/" + this.props.section + "/practices/"}
                  >
                    Prácticas
                  </Link>
                </div>

                <div className="section_button_wrapper">
                  <Link
                    className="section_button"
                    to={
                      "/" + this.props.section + "/administrative_procedures/"
                    }
                  >
                    Biblioteca de Trámites Administrativos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FunctionalComponent />
        <div className="modal_pop">
          <Popup modal open={this.getPopstate()}>
            {(close) => (
              <div className="modal">
                <div className="content">
                  <button className="close" onClick={close}>
                    &times;
                  </button>

                  <img alt="informative popup" src={client_splash} />
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    );
  }
}

function FunctionalComponent() {
  const { boot } = useIntercom();
  useEffect(() => {
    let usr_obj = JSON.parse(window.localStorage.getItem("user"));
    if (usr_obj) {
      boot({
        customAttributes: {
          api_base: "https://api-iam.intercom.io",
          name: usr_obj.first_name, // Full name
          email: usr_obj.email, // Email address
        },
      });
    }
  }, []);

  return (
    <></>
    // JSX for the functional component
  );
}

export default Despensas;
