import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Header from '../components/Header';

import { getSignedGenericResource } from '../services/ServerInterface';


function get_url_extension( url ) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}


class GenericResource extends Component {


  constructor(props) {
      // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      // const [numPages, setNumPages] = useState(null);
      super(props);


      let uuid = this.props.match.params.uuid
      this.player = React.createRef();


      this.state = {

        token: localStorage.getItem("token"),
        redirect: false,

        uuid,
        resource: null,
        url: null,
        resource_type: null,

      }

      let body = { resource_id: uuid }
      getSignedGenericResource(this.state.token, body).then ((response) => {

        if (response.ok) {

          let resource = response.body
          let url = resource.url
          let resource_type = get_url_extension(url)

          this.setState({resource, url, resource_type})

        } else if (response.status === 401) {

          this.setState({token: null})
          
        } else {

          this.setState({redirect: true})

        }

      }).catch((error) => {

          this.setState({redirect: true})

      })

  }

  // onDocumentLoadSuccess({ numPages }) {
  //   this.setNumPages(numPages);
  // }


  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render () {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    if (this.state.redirect) {
      return (<Redirect to={"/"}/>)
    }

    if (this.state.resource_type === "mp4"){
      return (

      <React.Fragment>

        <Header />

        <div className="resource">

          <div className="resource_content">
            <video className="resource_wrapper" controls autoPlay  ref={this.player} >
              <source src={this.state.url} type="video/mp4"/>
            </video>
          </div>


        </div>

      </React.Fragment>

    );

    }

    if (this.state.resource_type === "pdf"){

      return (

      <React.Fragment>

        <Header />

        <div className="resource">

          <div className="resource_content">
            <iframe className="resource_wrapper" id="resource_wrapper" src={this.state.url} title="resource" allowFullScreen={true} frameBorder="0"></iframe>
          </div>

        </div>

      </React.Fragment>
        )

    }
      return (null);

  }
}

export default GenericResource;
