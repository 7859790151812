// let BASE_URL = "http://localhost:8000";

let BASE_URL = "https://api.edenredtraining.mx";

function formatResponse([response, body]) {

	return {
		status: response.status,
		statusText: response.statusText,
		ok: response.ok,
		redirected: response.redirected,
		type: response.type,
		url: response.url,
		body: body,
	}

}

export function getToken(userData) {

	return new Promise((resolve, reject) => {

		let path = "/auth/issue/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json'
			},

			body: JSON.stringify(userData),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function sendRecoverEmail(payload) {

	return new Promise((resolve, reject) => {

		let path = "/auth/reset_password/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
			},

			body: JSON.stringify(payload),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function setFirstVideo(token) {

	return new Promise((resolve, reject) => {

		let path = "/auth/first_video/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function getCourses(token) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_course_list/"

		fetch(BASE_URL + path, {

			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function getResources(token) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_resource_list/"

		fetch(BASE_URL + path, {

			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}


export function getGenericResources(token) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_generic_resource_list/"

		fetch(BASE_URL + path, {

			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => {
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}


export function getPublicResources() {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_public_resources/"

		fetch(BASE_URL + path, {

			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
			},

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function getCourseSignature(token, body) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_course_signature/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

			body: JSON.stringify(body),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])		})
		
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}


export function sendFeedback(token, body) {

	return new Promise((resolve, reject) => {

		let path = "/feedback/message/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

			body: JSON.stringify(body),


		})
		.then((response) => { 
			return Promise.all([ response, response])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}


export function markMigration(token) {

	return new Promise((resolve, reject) => {

		let path = "/auth/mark_migration/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => { 
			return Promise.all([ response, response])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}




export function getSignedResource(token, body) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_resource_presigned/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

			body: JSON.stringify(body),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}


export function getSignedGenericResource(token, body) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_generic_resource_presigned/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

			body: JSON.stringify(body),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}



export function getPublicSignedResource(body) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_public_resource_presigned/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
			},

			body: JSON.stringify(body),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}



export function getTeasers(token) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_trailer_list/"

		fetch(BASE_URL + path, {

			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}

export function getSignedTeaser(token, body) {

	return new Promise((resolve, reject) => {

		let path = "/courses/get_trailer_presigned/"

		fetch(BASE_URL + path, {

			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'token ' + token,
			},

			body: JSON.stringify(body),

		})
		.then((response) => { 
			return Promise.all([ response, response.json() ])
		})
		.then((response) => {
			resolve(formatResponse(response))
		})
		.catch((error) => {
			reject(error);
		});

	});
	
}