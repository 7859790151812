import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import Header from '../components/Header';

class Document extends Component {

  constructor(props) {
    super(props)

    this.state = {

      document: this.props.match.params.document,

    }

  }

  componentDidMount() {

  }

  render () {

    if (this.state.redirect) {
      return (<Redirect to={"/guest/"}/>)
    }

    let url = "https://cdn.edenredtraining.mx/courses/public/aviso.pdf"

    return (

      <React.Fragment>

        <Header />

        <div className="resource">

          <div className="resource_content">
            <iframe 
              className="resource_wrapper" 
              id="resource_wrapper" 
              src={url} 
              title="resource" 
              allowFullScreen={true} 
              frameBorder="0">
            </iframe>
          </div>

        </div>

      </React.Fragment>

    );
  }
}

export default Document;
