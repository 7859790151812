/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useIntercom } from "react-use-intercom";
import { getToken, sendRecoverEmail } from '../services/ServerInterface'

import Header from '../components/Header';
import Tag from '../components/Tag';

// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';
import background from '../images/ic_keyboard.png';
import login_image from '../images/ic_login.svg'
import input_username from '../images/ic_username.svg'
import input_password from '../images/ic_password.svg'
import hide_eye from '../images/ic_hide_eye.svg'
import show_eye from '../images/ic_show_eye.svg'

import ResetPasswordPDF from '../assets/infografi_Reset_Password_01.pdf';

class Login extends Component {

  constructor(props) {
    super(props)

    this.state = {
      username: "",
      password: "",
      redirect: localStorage.getItem("token"),
      welcome: false,
      activate: false,
      showPassword: false
    }

    this.login = this.login.bind(this)
    this.onChange = this.onChange.bind(this)
    this.sendEmail = this.sendEmail.bind(this)

  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  login(event) {
    event.preventDefault();

    const serverResponse = document.getElementById('server_response')

    if (this.state.username && this.state.password) {

      serverResponse.innerHTML = "Iniciando sesion ...";

      let auth = this.state.username + ":" + this.state.password
      let encoded = btoa(auth)

      let credentials = {
        payload: encoded
      }

      getToken(credentials).then((response) => {

        if (response.ok) {

          let body = response.body

          let user = body.user
          let profile = body.profile

          if (profile !== undefined && !profile.has_pass_change) {

            this.sendEmail(user.username)

          } else {

            localStorage.setItem("token", body.token);
            localStorage.setItem("user", JSON.stringify(body.user));
            localStorage.setItem("profile", JSON.stringify(body.profile));
            localStorage.setItem("is_connect", JSON.stringify(body.profile.is_connect));
            localStorage.setItem("is_migrated", JSON.stringify(body.profile.is_migrated));
            localStorage.setItem("is_scann", JSON.stringify(body.profile.is_scann));
            localStorage.setItem("has_seen_migration", JSON.stringify(body.profile.has_seen_migration));


            if (!body.profile.has_first_video) {
              this.setState({ welcome: true })
            } else {
              this.setState({ redirect: true })
            }

          }

        } else if (response.status === 401) {
          serverResponse.innerHTML = "Usuario y/o contraseña incorrectos";
        } else {
          serverResponse.innerHTML = response.status + ": " + response.statusText;
        }

      })

    } else {
      serverResponse.innerHTML = "Ingresa tu usuario y contraseña";
    }

  }

  sendEmail(username) {

    let reason = "first";
    let payload = { username, reason }
    console.log(payload)

    sendRecoverEmail(payload).then((response) => {

      this.setState({ activate: true })

    })

  }

  render() {
    const { showPassword } = this.state;
    const inputType = showPassword ? "text" : "password";
    const passwordIcon = showPassword ? hide_eye : show_eye;

    if (this.state.activate) {
      return (<Redirect to={"/activate_account/"} />);
    }

    if (this.state.redirect) {
      return (<Redirect to={"/map/"} />);
    }

    if (this.state.welcome) {
      return (<Redirect to={"/welcome/"} />);
    }

    return (

      <React.Fragment>

        <Header />

        <div className="login">

          <div className="login_content">

            <img className="login_background" src={background} alt="background" />

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <form className="login_form">

              <div className="login_form_image_wrapper">
                <img className="login_form_image" src={login_image} alt="logo" />
              </div>

              <div className="login_form_inputs">
                <div className="login_form_input_wrapper margin_bottom">
                  <img className="login_form_icon" src={input_username} alt="icon_username" />
                  <input className="login_form_input login_username" type="text" name="username" placeholder="Correo" onChange={this.onChange} />
                </div>
                <div className="login_form_input_wrapper">
                  <img className="login_form_icon" src={input_password} alt="icon_password" />
                  <input className="login_form_input login_password" type={inputType} name="password" placeholder="Contraseña" onChange={this.onChange} />
                  <img className="login_form_icon" src={passwordIcon} alt="icon_password" onClick={() => this.setState({ showPassword: !showPassword })} />
                </div>
              </div>

              <input className="login_form_enter" type="submit" value="Entrar" onClick={this.login} />

              <p className="login_form_response" id="server_response"></p>

              <Link className="login_recover" to="/recover_password/">¿Olvidaste tu contraseña?</Link>
              <div className='login_reset_password_infography'>
                {/*<p>¿Es tu primera vez aqui? Da clic <a href='https://www.youtube.com/watch?v=RWs7zvnzg5Y'download=''>aquí</a></p>*/}
                <p>¿Tienes problemas para restablecer tu contraseña? Da clic <a href={ResetPasswordPDF} download=''>aquí</a></p>
              </div>
            </form>

          </div>

        </div>
        <FunctionalComponent />
      </React.Fragment>

    );

  }
}


function FunctionalComponent() {
  const {
    shutdown,
  } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}

export default Login;
