import React, { Component } from 'react';

class Settings extends Component {

  render() {

    return (

      <React.Fragment>

        <div className="not_found">

          <div className="not_found_content">

              <h1 className="not_found_label">404 Page Not Found</h1>

          </div>

        </div>

      </React.Fragment>

    );
  }
}

export default Settings;
