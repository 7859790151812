/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useIntercom } from "react-use-intercom";


import Popup from 'reactjs-popup';
import client_splash from '../images/despensas_splash_modal.png'

import mantenimiento_main_img from '../images/mantenimiento_main_img.png';
import mantenimiento_logo from '../images/ic_mantenimiento_logo.png';

class Mantenimiento extends React.Component {
  constructor(props) {
    super(props);
    this.getPopstate = this.getPopstate.bind(this);
  }

  getSoonButton() {
    if (this.props.soon) {
      return (<p className="mantenimiento_button" >Próximamente</p>);
    } else {
      return (<Link className="mantenimiento_button" to={"/" + this.props.section + "/practices/"}>Prácticas</Link>);
    }
  }

  getPopstate() {

    var pop_state = localStorage.getItem("ticketcar_pop_state");

    if (pop_state === null || isNaN(pop_state) || pop_state === "NaN") {
      console.log("Invlaid pop_state for section, reverting to default")
      localStorage.setItem("ticketcar_pop_state", 1);
      return true
    }

    pop_state = parseInt(pop_state)
    if (pop_state >= 3) {
      console.log("skipping pop for ticketcar")
      return false;
    }

    pop_state = parseInt(pop_state) + 1;
    localStorage.setItem("ticketcar_pop_state", pop_state)


    return true;
  }

  render() {
    return (
      <div className="mantenimiento_container">
        <div className='mantenimiento_container_bg_white'>
          <img className='mantenimiento_main_img' src={mantenimiento_main_img} alt="ticket_car_main_img" />
          <div className="mantenimiento_form_container">
            <img className="mantenimiento_logo" src={mantenimiento_logo} alt="mantenimiento" />
            <div className="mantenimiento_button_wrapper">
              <Link className="mantenimiento_button" to={"/" + this.props.section + "/card_management/"}>Gestión de Solicitudes</Link>
            </div>
            {/* <div className="mantenimiento_button_wrapper">
              {this.getSoonButton()}
            </div> */}
            <div className="mantenimiento_button_wrapper">
              <Link className="mantenimiento_button" to={"/" + this.props.section + "/administrative_procedures/"}>Biblioteca de Trámites Administrativos</Link>
            </div>
          </div>
        </div>

        <div className="modal_pop">
          <Popup
            // trigger={<a href="."> <img className="popup_modal_trigger" alt="modal trigger" src={modal_despensas} /> </a>}
            modal
            open={this.getPopstate()}
          >
            {close => (
              <div className="modal">
                <div className="content">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <img alt="informative popup" src={client_splash} />
                </div>
              </div>
            )}
          </Popup>
        </div>
        <FunctionalComponent />
      </div>


    );
  }
}

function FunctionalComponent() {
  const {
    shutdown,
  } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}

export default Mantenimiento;