import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';

class FaqViewer extends Component {
  constructor(props) {

    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      animation_class: "fadeIn"
    }
  }

  closeModal(){
    this.setState({
      animation_class: "fadeOut"
    })
    this.props.parent_ref.current.close()
  }

  render() {
    if(this.props.item.type === "pdf"){
      return (
        <div className="faqviewer_holder fadeIn">
          <iframe 
            className="faq_resource"
            src={this.props.item.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Ecovale PDF"
            >
          </iframe>
        </div>
      );
    }

    if(this.props.item.type === "mp4"){
      return (
        <div className="faqviewer_holder fadeIn">
          <video className="faq_resource" controls autoPlay  ref={this.player} >
            <source src={this.props.item.url} type="video/mp4"/>
          </video>
        </div>
      );
    }

  }

}

export default FaqViewer;