import React, { Component } from 'react';

class Tag extends Component {

  render() {

    let header = this.props.header
    let color = this.props.color
    let image = this.props.src
    let etraining = this.props.etraining

    let className = "tag " + color

    if (header) {
      className += " header"
    }

    if (etraining) {
      className += " etraining"
    }

    return (

      <div className={className}>
        <img className="tag_image" src={image} alt="banner" />
      </div>

    );

  }

}

export default Tag;
