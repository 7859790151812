/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useIntercom } from "react-use-intercom";

import Popup from 'reactjs-popup';
import client_splash from '../images/ticketcar_splash_modal.jpg';
import modal_despensas from '../images/modal_despensas.svg'

import ticket_car_main_img from '../images/ticket_car_main_img.png';
import ticket_car_logo from '../images/ic_ticket_car.svg';



class TicketCar extends React.Component {
  constructor(props) {
    super(props);
    this.getPopstate = this.getPopstate.bind(this);
  }

  getSoonButton() {
    if (this.props.soon) {
      return (<p className="ticket_car_button" >Próximamente</p>);
    } else {
      return (<Link className="ticket_car_button" to={"/" + this.props.section + "/practices/"}>Prácticas</Link>);
    }
  }

  getPopstate() {
    var pop_state = localStorage.getItem("ticketcar_pop_state");

    if (pop_state === null || isNaN(pop_state) || pop_state === "NaN") {
      console.log("Invlaid pop_state for section, reverting to default")
      localStorage.setItem("ticketcar_pop_state", 1);
      return true
    }

    pop_state = parseInt(pop_state)
    if (pop_state >= 3) {
      console.log("skipping pop for ticketcar")
      return false;
    }

    pop_state = parseInt(pop_state) + 1;
    localStorage.setItem("ticketcar_pop_state", pop_state)


    return true;
  }

  render() {
    return (
      <div className="ticket_car_container">
        <div className="ticket_car_form_container">
          <img className="ticket_car_logo" src={ticket_car_logo} alt="ticket_car" />
          <div className="ticket_car_button_wrapper">
            <Link className="ticket_car_button" to={"/" + this.props.section + "/card_management/"}>Autogestión de Tarjetas</Link>
          </div>
          <div className="ticket_car_button_wrapper">
            {this.getSoonButton()}
          </div>
          <div className="ticket_car_button_wrapper">
            <Link className="ticket_car_button" to={"/" + this.props.section + "/administrative_procedures/"}>Biblioteca de Trámites Administrativos</Link>
          </div>
        </div>
        <img className='ticket_car_main_img' src={ticket_car_main_img} alt="ticket_car_main_img" />

        <div className="modal_pop">
          <Popup
            trigger={<a href="."> <img className="popup_modal_trigger" alt="modal trigger" src={modal_despensas} /> </a>}
            modal
            open={this.getPopstate()}
          >
            {close => (
              <div className="modal">
                <div className="content">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <img alt="informative popup" src={client_splash} />
                </div>
              </div>
            )}
          </Popup>
        </div>
        <FunctionalComponent />
      </div>


    );
  }
}

function FunctionalComponent() {
  const {
    shutdown,
  } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}

export default TicketCar;