import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { sendRecoverEmail } from '../services/ServerInterface'

import Header from '../components/Header';
import Tag from '../components/Tag';

// import background from '../images/ic_keyboard.png';
// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';
// import login_image from '../images/ic_login.svg'
// import input_username from '../images/ic_username.svg'
// import input_password from '../images/ic_password.svg'

class Recover extends Component {

  constructor (props) {
    super(props)

    this.state = {

      username: "",
      redirect: false,
      adress: "",

    }

    this.onChange = this.onChange.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
 
  }

  onChange (event) {
    this.setState({[event.target.name]: event.target.value})
  }

  sendEmail (event) {
    event.preventDefault();

    const serverResponse = document.getElementById('server_response')

    if (this.state.username) {

      serverResponse.innerHTML = "Enviando instrucciones ...";

      let payload = {
          username: this.state.username,
          reason: "forgot"
      }

      sendRecoverEmail(payload).then ((response) => {
        if (response.ok) {

          const userInput = document.getElementById('recover_form')
          this.setState({
            "address": response.body.email
            }
          );

          if (userInput !== undefined) {
            userInput.value = ""
          }

          this.setState({
            "redirect": true
            }
          );
          this.forceUpdate();

        } else if (response.status === 401) {

          this.setState({token: null})

        } else if (response.status === 404) {

          serverResponse.innerHTML = "Correo no valido";

        } else {

          serverResponse.innerHTML = response.status + ": " + response.statusText;

        }

      })

    } else {

      serverResponse.innerHTML = "Ingresa tu correo";

    }

  }

  render () {
    if (this.state.redirect === true) {
      return (<Redirect to={{
        pathname: "/recover_password/result",
        state: {adress: this.state.adress} 
      }} 
      />)
    }
    return (

      <React.Fragment>

        <Header />

        <div className="login">

          <div className="login_content">

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <form className="login_form">

              <div className="login_form_title_wrapper">
                <h3 className="login_form_title">¿Olvidaste tu contraseña?</h3>
                <p className="login_form_flavor">Ingresa tu correo electronico para reiniciar tu contraseña</p>
              </div>

              <div className="login_form_inputs">
                <div className="login_form_input_wrapper">
                  {/*<img className="login_form_icon" src={input_username} alt="icon_username" />*/}
                  <input id="recover_form" className="login_form_input recover login_username" type="text" name="username" placeholder="Correo" onChange={this.onChange} />
                </div>
              </div>

              <input className="login_form_enter" type="submit" value="Entrar" onClick={this.sendEmail} />
            
              <p className="login_form_response" id="server_response"></p>

            </form>

          </div>

        </div>

      </React.Fragment>

    );

  }

}

export default Recover;
