import jsPDF from "jspdf";
import diplomaBase from '../images/diploma/certificadoEdenred.png';
import "jspdf-autotable";

import { font } from "./Ubuntu-MediumItalic-bolditalic";

const UNIT = "pt";
const PAGE_SIZE = "A4"; // Use A1, A2, A3 or A4
const ORIENTATION = "landscape"; // portrait or landscape

const exportDiplomaPDF = (studentName, section) => {
    const g = {
        despensas: {
            text: 'Vale Despensas Edenred',
            position: 690
        },
        mantenimiento: {
            text: 'Mantenimiento Fleet',
            position: 650
        },
        ecovale: {
            text: 'ecoVale Despensas Edenred',
            position: 715
        },
        fleet: {
            text: 'Fleet Despensas Edenred',
            position: 715
        },
        ticket_car: {
            text: 'Ticket Car Edenred',
            position: 650
        },
        empresarial: {
            text: 'Empresarial Edenred',
            position: 660
        },
        connect: {
            text: 'Edenred Connect',
            position: 650
        }
    }
    console.log(g[section]);
    const doc = new jsPDF(ORIENTATION, UNIT, PAGE_SIZE);

    doc.addFileToVFS("Ubuntu-MediumItalic.ttf", font);
    doc.addFont("Ubuntu-MediumItalic.ttf", "Ubuntu", "mediumItalic");


    doc.addImage(diplomaBase, 'JPEG', 0, 7, 839, 584);
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(40)
    doc.text(studentName, 415, 300, { align: "center" });
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(16)
    doc.setLineWidth(1500)
    doc.text(g[section]['text'], g[section]['position'], 382, { align: "right" });
    let current = new Date();
    let month = current.getMonth() < 9 ? '0' + (current.getMonth() + 1) : current.getMonth();
    let date = `${current.getDate()}/${month}/${current.getFullYear()}`;
    doc.setFontSize(22)
    doc.setFont('Helvetica', 'bold');
    doc.text(date, 470, 442);

    doc.output('dataurlnewwindow');
}

export const options = {
    exportDiplomaPDF
}