import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { getSignedTeaser } from '../services/ServerInterface'

import Header from '../components/Header';

import { SECTION_CODES } from '../constants/sections';

class Teaser extends Component {

  constructor(props) {
    super(props);

    this.state = {

      token: localStorage.getItem("token"),
      section: this.props.match.params.section,
      teaser: this.props.match.params.teaser,
      url: null,

    }

    let body = { trailer_id: this.state.teaser }
    getSignedTeaser(this.state.token, body).then ((response) => {

      if (response.ok) {

        localStorage.setItem(SECTION_CODES[this.state.section], true)
        this.setState({ url: response.body.url })

      } else if (response.status === 401) {

        this.setState({token: null})
        
      }

    }).catch((error) => {
      // TODO: Catch request error
    })

  }

  render() {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    return (

      <React.Fragment>

        <Header teaser={true} settings={true} section={this.state.section} />

        <div className="welcome">

          <div className="welcome_content">

            <div className="welcome_container">
              <iframe title="welcome_video" className="welcome_video" id="welcome_video" src={this.state.url} allowFullScreen={true} nodownload="true" frameBorder="0"></iframe>
            </div>

          </div>

        </div>

      </React.Fragment>

    );
  }
}

export default Teaser;
