import React from 'react';
// import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';

import Header from '../components/Header';


import connect_main_img from '../images/connect_main_img.png';
import connect_logo from '../images/ic_connect_scann.png';


import connect_button from '../images/connect_button.png';
import scann_button from '../images/scann_button.png';
// import modal_connect from '../images/modal_despensas.svg'
// import client_splash from '../images/client_splash_borderless.svg'

class ConnectScann extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      token: localStorage.getItem("token"),
      is_scann: localStorage.getItem("is_scann") === "true",
    }

    this.getPopstate = this.getPopstate.bind(this);
    this.getScannButton = this.getScannButton.bind(this);
    this.reRender = this.reRender.bind(this);
  }

  getPopstate(){
      var pop_state = localStorage.getItem("connect_pop_state");

      if (pop_state == null) {
        localStorage.setItem("connect_pop_state", 1);
        return true
      }

      pop_state = parseInt(pop_state)
      if (pop_state >= 3) {
        console.log("skipping pop for connect")
        return false;
      }

      pop_state = parseInt(pop_state) + 1;
      localStorage.setItem("connect_pop_state", pop_state)


      return true;
    }


  getScannButton(){
    if (this.state.is_scann){
      return (
        <div className="section_button_wrapper connect_button_link">
          <Link className="section_button" to={"/scann/administrative_procedures/"}>
            <img
              className="scann_button"
              src={scann_button}
              alt="Vale Scann"
            />
          </Link>
        </div>        
      )
    }
  }

  reRender(){
    this.forceUpdate();
  }

  render() {
        return(

       <React.Fragment> 
        <Header />

        <div className="section_content_connect">
          <div className="section_element_container">

              <div className="section_splash_wrapper hidden_wrapper">
                <div className="section_splash_image_wrapper">
                  <img src={connect_main_img} alt="Connect splash"></img>
                </div>
              </div>
              <div className="dummy hidden_wrapper">
              </div>
              <div className="section_form_container centered_form">
                <div className="section_right_wrapper">

                    <div className="section_image_wrapper">
                      <img src={connect_logo} alt="Connect Logo"></img>
                    </div>
                    <div className="section_form">

                      <div className="section_button_wrapper connect_button_link">
                        <Link
                          className="section_button"
                          to={
                            {
                              pathname: "/connect",
                              key: Math.random(),
                              state:{
                                applied: true,
                              }
                            }
                          }
                          >
                            <img
                              className="scann_button"
                              src={connect_button}
                              alt="Connect"
                            />
                        
                        </Link>
                      </div>

                      {this.getScannButton()}

                    </div>
                </div>
              </div>
          </div>
{/*        <div className="modal_pop">
          <Popup
            trigger={<a> <img className="modal_trigger" src={modal_connect}/> </a>}
            modal
            open={this.getPopstate()}
            >
            { close => (
                <div className="modal">
                  <div className="content">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <img src={client_splash}/>
                  </div>
                </div>
            )}
          </Popup>
        </div>*/}
        </div>
        </React.Fragment>

    );
    }
}


export default ConnectScann;
