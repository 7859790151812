import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { getPublicResources } from '../services/ServerInterface'

import Header from '../components/Header';
// import Tag from '../components/Tag';

// import background from '../images/ic_phone.png';

import despensas from '../images/ic_despensas.svg';
import ticket_car from '../images/ic_ticket_car.svg';
import ticket_car_vr from '../images/ic_ticket_car_vr.svg';
import fleet from '../images/ic_fleet.svg';
import connect from '../images/ic_mantenimiento.svg';
import empresarial from '../images/ic_empresarial.svg';
import mantenimiento from '../images/ic_mantenimiento_logo.png';

import ticket_car_splash from '../images/tc_guest_section_splash.png';
import despensas_splash from '../images/despensas_guest_section_splash.png';
import empresarial_splash from '../images/empresarial_guest_section_splash.png';
import mantenimiento_splash from '../images/mantenimiento_guest_splash.png';
import fleet_splash from '../images/fleet_guest_section_splash.svg';

import { SECTION_CODES } from '../constants/sections';


const SECTIONS_IMAGE = {
  "despensas": despensas,
  "ticket_car": ticket_car,
  "ticket_car_vr": ticket_car_vr,
  "fleet": fleet,
  "connect": connect,
  "empresarial": empresarial,
  "mantenimiento": mantenimiento
}

const SECTION_SPLASH = {
  "ticket_car": ticket_car_splash,
  "despensas": despensas_splash,
  "empresarial": empresarial_splash,
  "mantenimiento": mantenimiento_splash,
  "fleet" : fleet_splash
}


class Library extends Component {

  constructor(props) {
    super(props)

    this.state = {

      section: this.props.match.params.section,
      items: [],


    }

    let sectionCode = SECTION_CODES[this.state.section]

    getPublicResources().then((response) => {

      if (response.ok) {

        var items = []

        response.body.forEach(item => {

          if (item.section === sectionCode && !item.edentour && !item.first_video) {
            items.push(item)
          }

        })

        items.sort((a, b) => (a.name > b.name) ? 1 : -1)

        this.setState({ items })

      } else if (response.status === 401) {

        this.setState({ token: null })

      } else {
        // TODO: Handle server error
      }

    })

    this.getTagImage = this.getTagImage.bind(this)

  }

  getTagImage() {
    return SECTIONS_IMAGE[this.state.section]
  }

  getSplashImage() {
    return SECTION_SPLASH[this.state.section]
  }

  render() {

    const items = this.state.items.map(item => {

      let name = item.name
      let uuid = item.resource_id
      let url = "/guest/resource/" + uuid + "/"

      return (
        <li className="library_item" key={uuid}>
          <Link className="library_item_link" to={url}>{name}</Link>
        </li>
      )

    })

    if (this.state.section === "ticket_car") {
      return (

        <React.Fragment>

          <Header />

          <div className="library">

            <div className="library_content_ticket_car">

              <div className="library_payload_wrapper">
                <div className="library_container">
                  <div className="library_content_wrapper">
                    <div className="library_img_wrapper">
                      <img src={this.getTagImage()} alt="Tag"></img>
                    </div>

                    <div className="library_header_list_wrapper">
                      <header className="library_header">
                        <h3 className="library_title">Biblioteca de medios</h3>
                      </header>

                      <ul className="library_list">

                        {items}

                      </ul>
                    </div>
                  </div>
                </div>


                {/*                <div className="library_right_wrapper">
                  <div className="library_splash_wrapper">
                    <img src={this.getSplashImage()} alt="Splash"></img>
                  </div>
                </div>*/}
              </div>
            </div>

          </div>

        </React.Fragment>

      )
    }

    if (this.state.section === "despensas") {
      return (

        <React.Fragment>

          <Header />

          <div className="library">

            <div className="library_content_despensas">

              <div className="library_payload_wrapper">
                <div className="library_container">
                  <div className="library_content_wrapper">
                    <div className="library_img_wrapper">
                      <img src={this.getTagImage()} alt="Tag"></img>
                    </div>

                    <div className="library_header_list_wrapper">
                      <header className="library_header">
                        <h3 className="library_title">Biblioteca de medios</h3>
                      </header>

                      <ul className="library_list">

                        {items}

                      </ul>
                    </div>
                  </div>
                </div>


                <div className="library_right_wrapper">
                  <div className="library_splash_wrapper">
                    <img src={this.getSplashImage()} alt="Splash"></img>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </React.Fragment>

      )
    }

    if (this.state.section === "fleet") {
      return (

        <React.Fragment>

          <Header />

          <div className="library">

            <div className="library_content_fleet">

              <div className="library_payload_wrapper">
                <div className="library_container">
                  <div className="library_content_wrapper">
                    <div className="library_img_wrapper">
                      <img src={this.getTagImage()} alt="Tag"></img>
                    </div>

                    <div className="library_header_list_wrapper">
                      <header className="library_header">
                        <h3 className="library_title">Biblioteca de medios</h3>
                      </header>

                      <ul className="library_list">

                        {items}

                      </ul>
                    </div>
                  </div>
                </div>


                <div className="library_right_wrapper">
                  <div className="library_splash_wrapper">
                    <img src={this.getSplashImage()} alt="Splash"></img>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </React.Fragment>

      )
    }

    if (this.state.section === "mantenimiento") {
      return (

        <React.Fragment>

          <Header />

          <div className="library">

            <div className="library_content_mantenimiento">

              <div className="library_payload_wrapper">
                <div className="library_container">
                  <div className="library_content_wrapper">
                    <div className="library_img_wrapper">
                      <img src={this.getTagImage()} alt="Tag"></img>
                    </div>

                    <div className="library_header_list_wrapper">
                      <header className="library_header">
                        <h3 className="library_title">Biblioteca de medios</h3>
                      </header>

                      <ul className="library_list">

                        {items}

                      </ul>
                    </div>
                  </div>
                </div>


                <div className="library_right_wrapper">
                  <div className="library_splash_wrapper">
                    <img src={this.getSplashImage()} alt="Splash"></img>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </React.Fragment>

      )
    }


    if (this.state.section === "empresarial") {
      return (

        <React.Fragment>

          <Header />

          <div className="library">

            <div className="library_content_empresarial">

              <div className="library_payload_wrapper">
                <div className="library_container">
                  <div className="library_content_wrapper">
                    <div className="library_img_wrapper">
                      <img src={this.getTagImage()} alt="Tag"></img>
                    </div>

                    <div className="library_header_list_wrapper">
                      <header className="library_header">
                        <h3 className="library_title">Biblioteca de medios</h3>
                      </header>

                      <ul className="library_list">

                        {items}

                      </ul>
                    </div>
                  </div>
                </div>


                <div className="library_right_wrapper">
                  <div className="library_splash_wrapper">
                    <img src={this.getSplashImage()} alt="Splash"></img>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </React.Fragment>

      )
    }

  }

}

export default Library;
