/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from 'react';
import { Redirect, Link } from "react-router-dom";

import Header from '../components/Header';
import Tag from '../components/Tag';

import { useIntercom } from "react-use-intercom";

// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';
import background from '../images/ic_gears.png';
import default_pfp from '../images/default_pfp.png'

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      user: localStorage.getItem("user"),
      profile: localStorage.getItem("profile"),
      items: [],
    }

  }

  render() {

    if (!this.state.token || !this.state.user || !this.state.profile) {
      return (<Redirect to={"/logout/"} />)
    }

    let username, email, image
    let user = JSON.parse(this.state.user)
    let profile = JSON.parse(this.state.profile)

    if (user != null) {

      username = user.first_name + " " + user.last_name
      email = user.email

    }

    if (profile != null && profile.profile_pic != null) {
      image = profile.profile_pic.url
    }

    if (image == null) {
      image = default_pfp
    }

    return (

      <React.Fragment>

        <Header settings={true} />

        <div className="settings">

          <div className="settings_content">

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <img className="settings_background" src={background} alt="background" />

            <div className="settings_container">

              <header className="settings_header">
                <h3 className="settings_title">Configuración</h3>
              </header>

              <ul className="settings_list">

                <li className="settings_image_wrapper" key="settings_image">
                  <img className="settings_image" src={image} alt="profile" />
                </li>

                <li className="settings_info" key="settings_username">
                  <p className="settings_name">{username}</p>
                </li>

                <li className="settings_info" key="settings_email">
                  <p className="settings_email">{email}</p>
                </li>

                <li className="settings_item" key="settings_welcome">
                  <Link className="settings_item_link" to="/welcome/">Video de Bienvenida</Link>
                </li>

                <li className="settings_item" key="settings_privacy">
                  <Link className="settings_item_link" to="/document/privacy_policy/">Aviso de Privacidad</Link>
                </li>

                {/*<li className="settings_item" key="settings_terms">
                  <Link className="settings_item_link" to="/document/terms_conditions/">Términos y Condiciones</Link>
                </li>*/}

                {/*<li className="settings_item" key="settings_feedback">
                  <Link className="settings_item_link" to="/settings/feedback/">Comentarios</Link>
                </li>*/}

                <li className="settings_item logout" key="settings_logout">
                  <Link className="settings_item_link logout" to="/logout/">Cerrar Sesión</Link>
                </li>

              </ul>

            </div>

          </div>

        </div>
        <FunctionalComponent />
      </React.Fragment>

    );
  }
}

function FunctionalComponent() {
  const { shutdown } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}

export default Settings;
