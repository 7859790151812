import React, { Component } from 'react';
// import { Link } from "react-router-dom";

// import NotFound from './404';
import Header from '../components/Header';
// import Tag from '../components/Tag';

class Form extends Component {

  render () {

    // eslint-disable-next-line
    let form = this.props.match.params.form

    return (

      <React.Fragment>

        <Header />

        <div className="form">

          <div className="form_content">



          </div>

        </div>

      </React.Fragment>

    )

  }

}

export default Form;
