import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Header from '../components/Header';
import Tag from '../components/Tag';

// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';

class Recover extends Component {

  render () {

    return (

      <React.Fragment>

        <Header home={true} />

        <div className="login">

          <div className="login_content">

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <form className="login_form">

              <div className="login_form_title_wrapper">
                <p className="login_form_paragraph">Por cuestiones de seguridad, al ser tu primer ingreso debes restablecer tu contraseña, consulta el correo del administrador de la cuenta y sigue los pasos que se te indican.</p>
              </div>

              <Link className="login_form_enter" to="/login/" >Regresar</Link>

            </form>

          </div>

        </div>

      </React.Fragment>

    );

  }

}

export default Recover;
