/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useIntercom } from "react-use-intercom";

import Popup from 'reactjs-popup';

import modal_despensas from '../images/modal_despensas.svg'
import FaqViewer from './FaqViewer'

import fleet_main_img from '../images/fleet_main_img.svg';
import fleet_logo from '../images/ic_fleet.svg';
import { markMigration } from '../services/ServerInterface'


class Fleet extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.getMigrationPopstate = this.getMigrationPopstate.bind(this);
    this.getMigration = this.getMigration.bind(this);
    this.is_migrated = localStorage.getItem("is_migrated") === "true";

    // TODO: Check if we need to change this to fleet
    this.migrated_item = {
      url: "https://cdn.edenredtraining.mx/courses/public/ecovale_faq.mp4",
      type: "mp4"
    }
  }

  getMigration() {
    let is_migrated = localStorage.getItem("is_migrated") === "true";

    if (is_migrated) {
      return (
        <div className="fleet_button_wrapper">
          <Link className="fleet_button" to={"/" + this.props.section + "/faq/"}>Cliente Migrado</Link>
        </div>
      );
    }
    return;
  }


  getMigrationPopstate() {

    let is_migrated = localStorage.getItem("is_migrated") === "true";

    if (!is_migrated) {
      return false
    }

    var pop_state = localStorage.getItem("has_seen_migration");

    // If we got no pop_state we assume its fale
    if (pop_state === null) {
      pop_state = false;
    }

    pop_state = (pop_state === "true");

    // If we got it and its true, we skip it
    // If we just set it, we show it

    if (pop_state) {
      return false
    }

    // either way we reset it to true.
    localStorage.setItem("has_seen_migration", true)

    markMigration(this.token).then((response) => {
      if (response.status === 401) {
        console.log("An error occured while updating migration video status.")
      }
    })

    return true;

  }


  render() {
    return (
      <div className="fleet_container">
        <div className="fleet_form_container">
          <img className="fleet_logo" src={fleet_logo} alt="fleet" />
          <div className="fleet_button_wrapper">
            <Link className="fleet_button" to={"/" + this.props.section + "/card_management/"}>Autogestión de Fleet</Link>
          </div>
          <div className="fleet_button_wrapper">
            <Link className="fleet_button" to={"/" + this.props.section + "/administrative_procedures/"}>Biblioteca de medios</Link>
          </div>
        </div>
        <div className='fleet_main_img_module'>
          <img className='fleet_main_img' src={fleet_main_img} alt="fleet_main_img" />
        </div>
        <div className="modal_pop">
          <Popup
            trigger={<a href="."> <img className="popup_modal_trigger" alt="modal trigger" src={modal_despensas} /> </a>}
            modal
            open={this.getMigrationPopstate()}
          >
            {close => (
              <div className="feedback_modal fadeIn">
                <button className="close_feedback" onClick={close}>
                  &times;
                </button>

                <FaqViewer
                  parent_ref={this.popup_ref}
                  item={this.migrated_item}
                >
                </FaqViewer>
              </div>
            )}
          </Popup>
        </div>
        <FunctionalComponent />
      </div>
    );
  }
}


function FunctionalComponent() {
  const {
    shutdown,
  } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}

export default Fleet;