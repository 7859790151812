import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './scss/main.scss';

import Intro from './pages/Intro';
import Login from './pages/Login';
import Recover from './pages/Recover';
import RecoveryResult from './pages/RecoveryResult'
import Activation from './pages/Activation';
import Logout from './pages/Logout';

import Guest from './pages/Guest';
import Library from './pages/Library';
import Edentour from './pages/Edentour';
import Thanks from './pages/Thanks';
import Welcome from './pages/Welcome';
import Teaser from './pages/Teaser';

import Form from './pages/Form';
import Main from './pages/Main';
import Course from './pages/Course';
import Document from './pages/Document';
import Resource from './pages/Resource';

import GenericResource from './pages/GenericResource';
import PublicResource from './pages/PublicResource';

import Sections from './pages/Sections';
import Modules from './pages/Modules';

import FAQ from './pages/FAQ';

import Settings from './pages/Settings';

import Media from './pages/Media';

import FormSubmitted from './pages/FormSubmitted';
import NotFound from './pages/404';

import ConnectScann from './components/ConnectScann';

import { IntercomProvider} from "react-use-intercom";
import Testing from './components/Testing';
const INTERCOM_APP_ID = "ipoyh70i";

function App() {

	if (module.hot) {
  	module.hot.accept();
	}

	return (
		<IntercomProvider appId={INTERCOM_APP_ID} >
		<Router>

			<Switch>

				<Route exact path="/not_found/" component={NotFound} />

				<Route exact path="/logout/" component={Logout} />

				<Route exact path="/login/" component={Login} />

				<Route exact path="/recover_password/" component={Recover} />

				<Route exact path="/recover_password/result" component={RecoveryResult} />

				<Route exact path="/activate_account/" component={Activation} />
				
				<Route exact path="/form/:form/" component={Form} />

				<Route exact path="/map/" component={Main} />
				
				<Route exact path={"/testing/"} component={Testing}/>

				<Route exact path="/welcome/" component={Welcome} />
				
				<Route exact path="/thanks/" component={Thanks} />
				
				<Route exact path="/form_submitted/" component={FormSubmitted} />
				
				<Route exact path="/guest/" component={Main} />

				<Route exact path="/guest/:section/" component={Guest} />

				<Route exact path="/media/" component={Media} />

				<Route exact path="/scann/" component={ConnectScann} />

				<Route exact path="/guest/:section/edentour/" component={Edentour} />

				<Route exact path="/guest/:section/library/" component={Library} />

				<Route exact path="/guest/resource/:uuid/" component={PublicResource} />

				<Route exact path="/document/:document/" component={Document} />

				<Route exact path="/settings/" component={Settings} />

				<Route exact path="/settings/feedback/" component={Settings} />

				<Route exact path="/course/:uuid/" component={Course} />

				<Route exact path="/resource/:uuid/" component={Resource} />

				<Route exact path="/generic_resource/:uuid/" component={GenericResource} />

				<Route exact path="/:section/faq/" component={FAQ} />

				<Route exact path="/:section/:module/" component={Modules} />

				<Route exact path="/:section/teaser/:teaser" component={Teaser} />

				<Route exact path="/:section/" component={Sections} />

				<Route exact path="/" component={Intro} />

				<Route component={NotFound} />

			</Switch>

		</Router>
		</IntercomProvider>

	);

}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
