/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from 'react';
import { Redirect } from "react-router-dom";

import map_background from '../images/city_ERED_2024_v3.png';
import map_background_connect from '../images/ered_city_connect.png'
import map_background_guest from '../images/city_ERED_2024_v3_guest.png';

import Header from '../components/Header';

import { useIntercom } from "react-use-intercom";

class Main extends Component {

  constructor(props) {
    super(props)

    this.state = {

      token: localStorage.getItem("token"),
      guest: window.location.href.includes("guest"),
      form: localStorage.getItem("multipart"),
      is_connect: localStorage.getItem("is_connect") === "true",
      // is_connect: true,
      redirect: false,

    }

    this.buttonClick = this.buttonClick.bind(this)
    this.getMainContent = this.getMainContent.bind(this)
    this.onFormSubmitted = this.onFormSubmitted.bind(this)
    this.loadCanvas = this.loadCanvas.bind(this)
    this.getCanvasBG = this.getCanvasBG.bind(this)
  }

  buttonClick(event) {

    if (this.state.guest) {
      if (event.target.value === 'ecovale' || event.target.value === 'mantenimiento') {
        window.location.href = 'https://www.edenred.mx/';
        return
      }
      this.props.history.push("/guest/" + event.target.value + "/")

    } else {
      this.props.history.push("/" + event.target.value + "/")
    }

  }

  onFormSubmitted(event) {

    // console.log("eventName", event.data.eventName)
    // console.log("origin", event.origin)
    // console.log(event)

    // console.log("data", event.data)

    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
      // someAnalyticsLib('formSubmitted');
      console.log("Form Submitted!");
    }

    if (event.origin === 'https://share.hsforms.com' && event.data.eventName === "onFormSubmitted") {

      if (this.state.guest) {
        localStorage.setItem("multipart", true)
        this.setState({ form: true })
      }

      this.loadCanvas()

    }

  }

  getButtons() {

    if (this.state.is_connect) {

      return (
        <React.Fragment>
          <button ref="button_05" type="button" className="button button_05" value="scann" onClick={this.buttonClick}></button>
          <button ref="button_07" type="button" className="button button_07" value="media" onClick={this.buttonClick}></button>
        </React.Fragment>
      )

    }

    if (this.state.guest) {
      return (
        <React.Fragment>
          <button ref="button_01" type="button" className="button button_01" value="despensas" onClick={this.buttonClick}></button>
          <button ref="button_02" type="button" className="button button_02" value="ticket_car" onClick={this.buttonClick}></button>
          <button ref="button_03" type="button" className="button button_03" value="mantenimiento" onClick={this.buttonClick}></button>
          {/*<button ref="button_03" type="button" className="button button_03" value="ticket_car_vr" onClick={this.buttonClick}></button>*/}
          <button ref="button_04" type="button" className="button button_04" value="fleet" onClick={this.buttonClick}></button>
          {/* <button ref="button_04" type="button" className="button button_04" value="ecovale" onClick={this.buttonClick}></button> */}
          <button ref="button_06" type="button" className="button button_06" value="empresarial" onClick={this.buttonClick}></button>
          {/*<button ref="button_07" type="button" className="button button_07" value="media" onClick={this.buttonClick}></button>*/}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <button ref="button_01" type="button" className="button button_01" value="despensas" onClick={this.buttonClick}></button>
        <button ref="button_02" type="button" className="button button_02" value="ticket_car" onClick={this.buttonClick}></button>
        <button ref="button_03" type="button" className="button button_03" value="mantenimiento" onClick={this.buttonClick}></button>
        <button ref="button_04" type="button" className="button button_04" value="fleet" onClick={this.buttonClick}></button>
        {/* <button ref="button_04" type="button" className="button button_04" value="ecovale" onClick={this.buttonClick}></button> */}
        <button ref="button_06" type="button" className="button button_06" value="empresarial" onClick={this.buttonClick}></button>
        <button ref="button_07" type="button" className="button button_07" value="media" onClick={this.buttonClick}></button>
      </React.Fragment>
    );
  }

  getCanvasBG() {
    if (this.state.is_connect) {
      return map_background_connect
    }

    if (this.state.guest) {
      return map_background_guest
    }

    return map_background
  }

  loadCanvas() {

    const canvas = this.refs.canvas

    if (canvas !== undefined && canvas !== null) {

      const context = canvas.getContext("2d")
      const img = this.refs.image

      const canvasWidth = canvas.width
      const canvasHeight = canvas.height

      var imageWidth = canvasWidth
      var imageHeight = canvasHeight

      function drawCanvas() {
        console.log("Drawing canvas")
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.drawImage(img, 0, 0, imageWidth, imageHeight)
      }

      img.onload = () => { drawCanvas() }

    }

  }

  componentDidMount() {

    this.loadCanvas()
    // console.log(window)
    window.addEventListener('message', this.onFormSubmitted)

    let frame = document.getElementById("main_frame")

    if (frame !== undefined && frame !== null) {

      // console.log(frame)
      frame.addEventListener('message', this.onFormSubmitted)
      // console.log("frame message setted")

    }

  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onFormSubmitted)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  getMainContent() {

    if (false) {

      return (<iframe
        ref="frame"
        id="main_frame"
        className="main_frame"
        src="https://share.hsforms.com/10KGe321lRuOKXlTO6DkSFwaj1b"
        title="Edenred - Etraining"
        onLoad={this.onFrameLoad}
        sandbox="allow-same-origin allow-top-navigation allow-scripts"
        referrerPolicy="origin-when-cross-origin"
        samesite="None">
      </iframe>)

    } else {

      return (

        <div className="canvas_wrapper">

          <canvas ref="canvas" className="canvas" width={2560} height={1440} />

          <div ref="buttons_wrapper" className="buttons_wrapper" >
            <div ref="buttons" className="buttons" >
              {this.getButtons()}
            </div>
          </div>

          <img ref="image" src={this.getCanvasBG()} className="hidden" alt="map_background" />

        </div>

      )

    }

  }

  render() {

    if (!this.state.guest && !this.state.token) {
      return (<Redirect to={"/logout/"} />)
    }

    return (

      <React.Fragment>

        <Header />

        <div className="main">

          <div className="main_content">

            {this.getMainContent()}

          </div>

        </div>
        <FunctionalComponent />
      </React.Fragment>

    );
  }
}


function FunctionalComponent() {
  const { boot } = useIntercom();
  useEffect(() => {
    let usr_obj = JSON.parse(window.localStorage.getItem('user'))
    if (usr_obj) {
      boot({
        customAttributes: {
          api_base: "https://api-iam.intercom.io",
          name: usr_obj.first_name, // Full name
          email: usr_obj.email, // Email address
        },
      })
    }
    // shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}



export default Main;
