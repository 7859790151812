import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

import Header from '../components/Header';

import { getPublicResources, getPublicSignedResource } from '../services/ServerInterface';

import { SECTION_CODES } from "../constants/sections";


function get_url_extension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

class Edentour extends Component {

  constructor(props) {
    super(props);

    this.state = {
      section: this.props.match.params.section,
      resource_type: null,

    }

    let sectionCode = SECTION_CODES[this.state.section]

    getPublicResources().then((response) => {

      if (response.ok) {

        var edentour = undefined

        response.body.forEach(item => {

          if (item.section === sectionCode && item.edentour) {
            edentour = item
          }

        })

        if (edentour !== undefined) {
          this.getSignedEdentour(edentour)
        } else {
          // TODO: No hay edentour de la seccion
        }


      } else if (response.status === 401) {

        this.setState({ token: null })

      } else {
        // TODO: Handle server error
      }

    })

    this.getSignedEdentour = this.getSignedEdentour.bind(this)

  }

  getSignedEdentour(edentour) {


    let body = { resource_id: edentour.resource_id }
    getPublicSignedResource(body).then((response) => {

      if (response.ok) {

        let resource = response.body
        let url = resource.url
        let resource_type = get_url_extension(url)


        this.setState({ resource, url, resource_type })

      } else if (response.status === 401) {

        this.setState({ token: null })

      } else {
        this.setState({ redirect: true })
      }

    }).catch((error) => {
      // TODO: Catch request error
    })

  }


  render() {

    if (this.state.resource_type === "mp4") {
      return (

        <React.Fragment>

          <Header />

          <div className="resource">

            <div className="resource_content">
              <video className="resource_wrapper" controls autoPlay ref={this.player} >
                <source src={this.state.url} type="video/mp4" />
              </video>
            </div>


          </div>

        </React.Fragment>

      );

    }

    if (this.state.resource_type === "pdf") {

      return (

        <React.Fragment>

          <Header />

          <div className="resource">

            <div className="resource_content">
              <iframe className="resource_wrapper" id="resource_wrapper" src={this.state.url} title="resource" allowFullScreen={true} frameBorder="0"></iframe>
            </div>

          </div>

        </React.Fragment>
      )

    }
    return (null);
  }
}

export default Edentour;
