import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import { getCourses, getTeasers } from '../services/ServerInterface'

import NotFound from './404';
import Header from '../components/Header';

import TicketCar from '../components/TicketCar';
import Despensas from '../components/Despensas';
import Connect from '../components/Connect';
import ConnectScann from '../components/ConnectScann';
// import Ecovale from '../components/Ecovale';
import Fleet from '../components/Fleet';
import Mantenimiento from '../components/Mantenimiento';

import imgEmpresarial01 from '../assets/Assets_Empresarial/image_1_01.png';
import logoEmpresarial from '../assets/Assets_Empresarial/logo_1.png';
import botonAutegestionTarjetas from '../assets/Assets_Empresarial/button_1_01.png';

import despensas from '../images/ic_despensas.svg';
import ticket_car from '../images/ic_ticket_car.svg';
import ticket_car_vr from '../images/ic_ticket_car_vr.svg';
import ecovale from '../images/ic_restaurante.svg';
import connect from '../images/ic_mantenimiento.svg';
import empresarial from '../images/ic_empresarial.svg';
import mantenimiento from '../images/ic_mantenimiento.png';

import { SECTION_CODES, SECTIONS, SECTIONS_URL } from '../constants/sections';


const SECTIONS_IMAGE = {
  "despensas": despensas,
  "ticket_car": ticket_car,
  "ticket_car_vr": ticket_car_vr,
  "ecovale": ecovale,
  "connect": connect,
  "connect_scann": connect,
  "empresarial": empresarial,
  "mantenimiento": mantenimiento,
}

class Sections extends Component {

  constructor(props) {
    super(props)

    this.state = {

      token: localStorage.getItem("token"),
      is_connect: localStorage.getItem("is_connect") === "true",
      is_scann: localStorage.getItem("is_scann") === "true",
      section: this.props.match.params.section,
      form: localStorage.getItem(this.props.match.params.section),
      soon: false,
      isCourseEmpty: true,
      teaser: null,
      fetching: true

    }
    this.courses = []

    let sectionCode = SECTION_CODES[this.state.section]

    getTeasers(this.state.token).then((response) => {
      this.state.fetching = false;
      if (response.ok) {

        response.body.forEach(item => {

          // We get a list of sections that a user is missing
          if (item.section.toString() === sectionCode && (sectionCode !== "5" || sectionCode !== "8")) {

            // If theres a section and the user hasnt seen it, set it and redirect him.
            if (item !== null && !this.getTeaserConfirmation()) {

              if (item.trailer === null) {
                console.log("invalid/inactive trailer, check if correct trailer is loaded for section.")
                item.trailer = {
                  "0": {
                    "section": 0,
                    "trailer": {
                      "trailer_id": "be7214c8-69be-47f2-807d-949260044433",
                      "name": "Invalid Trailer",
                      "file": "https://www.youtube.com/watch?v=4Pkkre8Hw_A",
                      "resource_type": null
                    }
                  }
                }
              }
              localStorage.setItem(SECTION_CODES[this.state.section], true)
              this.setState({ teaser: item.trailer.trailer_id, redirect: true })

            }

          }

        })

      } else if (response.status === 401) {

        this.setState({ token: null })

      } else {
        // TODO: Handle server error
      }

    })

    getCourses(this.state.token).then((response) => {
      this.state.fetching = false;
      if (response.ok) {

        var items = []

        response.body.forEach(item => {

          if ((item.module === "t" || item.module === "b") && item.section === sectionCode) {
            items.push(item)
          }

        })
        let soon = items.length === 0
        let isCourseEmpty = items.length === 0;
        this.setState({ soon, isCourseEmpty })

      } else if (response.status === 401) {

        this.setState({ token: null })

      }

    })

    this.getTeaserConfirmation = this.getTeaserConfirmation.bind(this)
    this.getFormConfirmation = this.getFormConfirmation.bind(this)
    this.getSectionContent = this.getSectionContent.bind(this)
    this.onFormSubmitted = this.onFormSubmitted.bind(this)
    this.getSoonButton = this.getSoonButton.bind(this)
    this.getTagImage = this.getTagImage.bind(this)
    this.showHubspot = this.showHubspot.bind(this)

  }

  getFormConfirmation() {
    return localStorage.getItem(this.state.section)
  }

  getTeaserConfirmation() {
    return localStorage.getItem(SECTION_CODES[this.state.section])
  }

  getTagImage() {
    return SECTIONS_IMAGE[this.state.section]
  }

  getSoonButton() {

    if (this.state.soon) {
      return (<p className="sections_button" >Próximamente</p>)
    } else {
      return (<Link className="sections_button" to={"/" + this.state.section + "/practices/"}>Prácticas</Link>)
    }

  }


  showHubspot() {
    // Hacks dont ask
    let connects = ["connect", "connect_scann"]
    let section = this.state.section

    if (section === "connect_scann") {
      section = "connect"
    }
    // eslint-disable-next-line
    return (SECTIONS.includes(section) && this.state.isCourseEmpty) && !this.state.form || (this.state.is_connect && !connects.includes(section))

  }

  getSectionContent() {

    var isValid = false

    SECTIONS.forEach(item => {
      if (item === this.state.section) { isValid = true }
    })

    if (!isValid) {
      return <NotFound />
    }

    if (this.showHubspot()) {

      let url = SECTIONS_URL[this.state.section]
      if (this.state.fetching) {
        // ()=>{}
      } else {
        window.location.href = "https://www.edenred.mx/"
        return (<iframe
          id="sections_frame"
          className="sections_frame"
          src={url}
          title="Edenred - Etraining"
          onLoad={this.onFrameLoad}>
        </iframe>)
      }

    } else {
      if (this.state.section === SECTIONS[0]) {
        return (
          <Despensas
            section={this.state.section}
          />
        )
      }

      if (this.state.section === SECTIONS[1]) {
        return (
          <TicketCar
            section={this.state.section}
            soon={this.state.soon}
          />
        )
      }


      // if(this.state.section === SECTIONS[2]) {
      //   return (
      //     <Connect
      //       section={this.state.section} 
      //       soon={this.state.soon} 
      //     />
      //   )
      // }

      if (this.state.section === SECTIONS[3]) {
        return (
          <Fleet
            section={this.state.section}
            soon={this.state.soon}
          />
        )
      }

      // if(this.state.section === SECTIONS[4]) {
      //   return (
      //     <Connect
      //       section={this.state.section} 
      //       soon={this.state.soon} 
      //     />
      //   )
      // }


      if (this.state.section === SECTIONS[5]) {
        return (
          <Connect
            section={this.state.section}
            soon={this.state.soon}
          />
        )
      }

      if (this.state.section === SECTIONS[6]) {
        return (
          <ConnectScann
            section={this.state.section}
            soon={this.state.soon}
          />
        )
      }

      if (this.state.section === SECTIONS[7]) {
        return (
          <Mantenimiento
            section={this.state.section}
            soon={this.state.soon}
          />
        )
      }


      return (
        <div className="sections_content_empresarial" >

          <div className='container_empresarial'>
            <img className='img_empresarial' src={imgEmpresarial01} alt='img_1_01.png' />
            <div className='logo_container'>
              <img className='logo_empresarial' src={logoEmpresarial} alt='Logo Empresarial Fintech Edenred' />
              <Link className="link_card_mng" to={"/" + this.state.section + "/card_management/"}>
                <img className='button_card_mng' src={botonAutegestionTarjetas} alt='Logo Empresarial Fintech Edenred' />
              </Link>
            </div>
          </div>
        </div>

      )

    }

  }

  onFormSubmitted(event) {

    if (event.origin === 'https://share.hsforms.com' && event.data.eventName === "onFormSubmitted") {

      localStorage.setItem(this.state.section, true)
      this.setState({ form: true })

    }

  }

  componentDidMount() {
    window.addEventListener('message', this.onFormSubmitted)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onFormSubmitted)
  }

  render() {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"} />)
    }

    if (this.state.section !== SECTIONS[0] && this.state.form) {
      return (<Redirect to={"/thanks/"} />)
    }

    if (this.state.redirect) {
      return (<Redirect to={"/" + this.state.section + "/teaser/" + this.state.teaser + "/"} />)
    }

    return (

      <React.Fragment>

        <Header />

        <div className="sections">

          {this.getSectionContent()}

        </div>

      </React.Fragment>

    )

  }

}

export default Sections;
