import React, { Component } from 'react';

import { Redirect} from "react-router-dom";

import Popup from 'reactjs-popup';

import NotFound from './404';

import Header from '../components/Header';

import FaqViewer from '../components/FaqViewer';

import ecovale from '../images/ic_ecovale.png';
import logo_red from '../images/logo_red.svg';

import payloads from '../services/StaticPayloads'


class FAQ extends Component {

  constructor(props) {
    super(props);

    this.popup_ref = React.createRef();

    this.state = {
      token: localStorage.getItem("token"),
      section: this.props.match.params.section,
      is_migrated: localStorage.getItem("is_migrated") === "true",
      items: [],

    }

    this.getTagImage = this.getTagImage.bind(this);
    this.getFeedbackModal = this.getFeedbackModal.bind(this);
  }


  componentDidMount(){
    var items = []

    if (this.state.section === "ecovale"){
      items = payloads.ecovale
    }

    this.setState({ items})

  }



  getFeedbackModal(item){

    return(
          <Popup
            modal
            // eslint-disable-next-line
            trigger={<button className="modules_item_button" to={item.url}>{item.name}</button>}
            ref={this.popup_ref}

            >
              {close => (
                <div className="feedback_modal fadeIn">
                  <button className="close_feedback" onClick={close}>
                    &times;
                  </button>

                  <FaqViewer
                    parent_ref={this.popup_ref}
                    item={item}
                  >
                  </FaqViewer>
                </div>

              )}
          </Popup>
    )
  
  }

  getTagImage() {

    if (this.state.section === "ecovale") {
      return ecovale
    }

    return logo_red

  }


  render() {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    const items = this.state.items.map(item => {

      let uuid = item.uuid

      return (
        <li className="modules_item" key={uuid}>
          {this.getFeedbackModal(item)}
        </li>
      )

    })

    if(this.state.section === "ecovale") {
      return (
        <>
          <Header card_activity={true}/>
          <div className="ecovale_modules_container ecovale_faq">
              <img className='ecovale_logo' src={this.getTagImage()} alt="ecovale logo"/>
              <div className="modules_container">
                <header className="ecovale_header">
                  <h3 className="ecovale_title">Cliente Migrado</h3>
                </header>
                <ul className="modules_list">
                  {items}
                </ul>
              </div>
          </div>
        </>
      )
    }
    return (<NotFound /> );
  }
}

export default FAQ;
