import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import Header from '../components/Header';
import { getCourses, getCourseSignature } from '../services/ServerInterface';

const SECTION_NAMES = {
  "1": "despensas",
  "2": "ticket_car",
  //"3": "ticket_car_vr",
  "3": "empresarial",
  "4": "ecovale",
  "5": "connect",
  "6": "mantenimiento",
}


const MODULES_NAMES = {
  "b": "card_management",
  "t": "practices",
}

function encodeQueryData(data) {

   const ret = []
   for (let d in data) {
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
   }

   return ret.join('&')

}

class Course extends Component {

  constructor(props) {
    super(props);

    this.state = {

      token: localStorage.getItem("token"),
      redirect: false,

      payload: {},

    }

    this.delay = undefined

    this.course = undefined

    let course_id = this.props.match.params.uuid
    let body = { course_id }

    getCourses(this.state.token).then ((response) => {

      if (response.ok) {

        response.body.forEach(item => {

          if (item.course_id === course_id) {

            this.course = item

          }

        })

      } else if (response.status === 401) {

        this.setState({token: null})

      }

    })

    getCourseSignature(this.state.token, body).then ((response) => {

      if (response.ok) {

        let cookies = response.body

        let query = { 

          'endpoint': "https://api.edenredtraining.mx/xapi", 
          'auth': "token " + this.state.token,
          'actor': '{"name": "dummy", "mbox": "mailto:dummy@edenredtraining.mx", "objectType": "Agent"}',
          'activity_id': cookies.course_id

        }

        let url = cookies.launch_object + "?" + encodeQueryData(query)

        let payload = { cookies, url }
        this.setState({payload})

      } else if (response.status === 401) {

        this.setState({token: null})
        
      } else {
        this.setState({redirect: true})
      }

    }).catch((error) => {
      this.setState({redirect: true})
    })

    this.frame = React.createRef()

    this.messageRecieved = this.messageRecieved.bind(this)

  }

  componentDidMount() {

    window.addEventListener('message', this.messageRecieved)

  }

  componentDidUpdate() {

    let frame = this.frame.current

    if (frame !== null) {
      frame.contentWindow.postMessage(this.state.payload, "*")
    }

    this.delay = setTimeout(function() {
      
      let frame = this.frame.current

      if (frame !== null) {
        frame.contentWindow.postMessage(this.state.payload, "*")
      }

    }.bind(this), 1000)

  }

  componentWillUnmount() {

    if (this.delay) {
      clearTimeout(this.delay)
      this.delay = null
    }

  }

  messageRecieved(event) {

    if (event.data.action === "closeWindow") {
      this.setState({ redirect: true })
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  render () {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    if (this.state.redirect) {
      if (this.course !== undefined) {
        let section = "/" + SECTION_NAMES[this.course.section] + "/" + MODULES_NAMES[this.course.module] + "/"
        return (<Redirect to={section}/>)
      } else {
        return (<Redirect to={"/"}/>)
      }

    }
    return (

      <React.Fragment>

        <Header />

        <div className="course">

          <div className="course_content">

            <iframe ref={this.frame} className="course_wrapper" id="course_wrapper" title="course" src="https://cdn.edenredtraining.mx/courses/public/dummy_launcher.html" >
            </iframe>

          </div>

        </div>

      </React.Fragment>

    );
  }
}

export default Course;
