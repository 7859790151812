// eslint-disable-next-line
export default {
    ecovale:[
        {
            name: "Nueva Experiencia Ecovale",
            url: "https://cdn.edenredtraining.mx/courses/public/ecovale_faq.mp4", // CDN Public URL
            uuid: 1,
            type: "mp4"
        },
        {
            name: "Ecovale FAQ PDF",
            url: "https://cdn.edenredtraining.mx/courses/public/aviso.pdf", // CDN Public URL
            uuid: 2,
            type: "pdf"
        }
    ],
}
