import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Header from '../components/Header';
import Tag from '../components/Tag';

// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';

class RecoveryResult extends Component {
  // constructor (props) {
  //   super(props)

  // }


  render () {
    let adress = this.props.location.state.adress;

    return (

      <React.Fragment>

        <Header home={true} />

        <div className="login">

          <div className="login_content">

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <form className="login_form">

              <div className="login_form_title_wrapper">
                <p className="login_form_paragraph">Revisa tu bandeja de entrada, te enviamos información para restablecer tu contraseña al correo {adress}</p>
              </div>

              <Link className="login_form_enter" to="/login/" >Regresar</Link>

            </form>

          </div>

        </div>

      </React.Fragment>

    );

  }

}

export default RecoveryResult;
