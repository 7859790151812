/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect} from 'react';
import Header from '../components/Header';

import { useIntercom } from "react-use-intercom";
// import logo_red from '../images/logo_red.svg';

// import bg_beneficios from '../images/tower_beneficios_bg.png'
// import bg_flota from '../images/tower_flota_bg.png'
// import bg_pagos from '../images/tower_pagos_bg.png'


class TowerDesktop extends Component {

  render() {

    // let header = this.props.header
    // let color = this.props.color
    // let image = this.props.src
    // let etraining = this.props.etraining

    // let className = "tag " + color

    // if (header) {
    //   className += " header"
    // }

    // if (etraining) {
    //   className += " etraining"
    // }

    return (

      <React.Fragment>

        <Header />

        <div className="tower_modules">
          <div className="content_container">

            <div className="title_container">
              <p>Ofrecemos soluciones de pago de propósito especifico para la <span className="title_span span_beneficios">alimentación</span>, <span className="title_span span_flota">movilidad</span> y los <span className="title_span span_pagos">pagos corporativos</span></p>
            </div>

            <div className="resource_container">

              <div className="tower_module">
                  <div className="tower_module_content tower_module_beneficios">
                    <div className="tower_flavor flavor_beneficios">
                      <div className="flavor_container">
                        <p className="flavor_header">Beneficios</p>
                        <p>para empleados</p>
                      </div>

                    </div>

                    <div className="tower_items_container">
                      <ul className="tower_items_list">
                        
                          {this.props.items_beneficios}

                      </ul>
                    </div>
                    
                  </div>

                  <div className="view_more">
                    <p>Ver más</p>
                  </div>
                
              </div>

              <div className="tower_module">
                  <div className="tower_module_content tower_module_flota">
                    <div className="tower_flavor flavor_flota">
                      <div className="flavor_container">
                        <p className="flavor_header">Flota</p>
                        <p>y mantenimiento</p>
                      </div>

                    </div>

                    <div className="tower_items_container">
                    <ul className="tower_items_list">
                      
                        {this.props.items_flota}

                    </ul>
                    </div>
                    
                  </div>

                  <div className="view_more">
                    <p>Ver más</p>
                  </div>
                
              </div>

              <div className="tower_module">
                  <div className="tower_module_content tower_module_pagos">
                    <div className="tower_flavor flavor_pagos">
                      <div className="flavor_container">
                        <p className="flavor_header">Pagos</p>
                        <p>Corporativos</p>
                      </div>

                    </div>

                    <div className="tower_items_container">
                    <ul className="tower_items_list">
                      
                        {this.props.items_pago}

                    </ul>
                    </div>
                    
                  </div>

                  <div className="view_more">
                    <p>Ver más</p>
                  </div>
                
              </div>

            </div>

          </div>
        </div>
        <FunctionalComponent/>

      </React.Fragment>

    );

  }
}

function FunctionalComponent() {
  const {
    shutdown,
  } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])

  

  return (<>
  </>
    // JSX for the functional component
  );
}


export {
  TowerDesktop,
}


// class TowerDesktop extends Component {

//   render() {

//     // let header = this.props.header
//     // let color = this.props.color
//     // let image = this.props.src
//     // let etraining = this.props.etraining

//     // let className = "tag " + color

//     // if (header) {
//     //   className += " header"
//     // }

//     // if (etraining) {
//     //   className += " etraining"
//     // }

//     return (

//       <React.Fragment>

//         <Header />

//         <div className="modules">

//           <div className="modules_content">

//           <div className="payload_wrapper">
//               <div className="content_payload_wrapper">
//                 <div className="modules_container_wrapper">        
//                   <div className="modules_container">
//                     <div className="modules_header_logo_wrapper">
//                       <select>
//                           <option value="volvo" className="modules_header_logo dropdown_despensas">Vale Despensas</option>
//                       </select>
//                       {/*<img className="modules_header_logo" src={logo_red} alt="Edenred Logo" />*/}
//                     </div>


//                     <header className="modules_header">
//                       <h3 className="modules_title">{this.props.module}</h3>
//                     </header>

//                     <ul className="modules_list">
                      
//                       {this.props.items}

//                     </ul>
//                   </div>
//                 </div>
//               </div>

//               <div className="modules_splash_container">
//                 <div className="modules_splash_wrapper">
//                   <img className="modules_splash" src={modules_splash} alt="Media Splash"/>
//                 </div>
//               </div>
//           </div>
//           </div>

//         </div>

//       </React.Fragment>

//     );

//   }
// }
