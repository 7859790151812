import React from 'react';
// import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';

import connect_main_img from '../images/connect_main_img.png';
import connect_logo from '../images/ic_connect.png'
// import modal_connect from '../images/modal_despensas.svg'
// import client_splash from '../images/client_splash_borderless.svg'

class Connect extends React.Component {

  constructor(props) {
    super(props);
    this.getPopstate = this.getPopstate.bind(this);
  }

  getPopstate(){
      var pop_state = localStorage.getItem("connect_pop_state");

      if (pop_state == null) {
        localStorage.setItem("connect_pop_state", 1);
        return true
      }

      pop_state = parseInt(pop_state)
      if (pop_state >= 3) {
        console.log("skipping pop for connect")
        return false;
      }

      pop_state = parseInt(pop_state) + 1;
      console.log(pop_state)
      localStorage.setItem("connect_pop_state", pop_state)


      return true;
    }

  render() {
        return(

        <div className="section_content_connect">
          <div className="section_element_container">

              <div className="section_splash_wrapper">
                <div className="section_splash_image_wrapper">
                  <img src={connect_main_img} alt="Connect splash"></img>
                </div>
              </div>
              <div className="dummy">
              </div>
              <div className="section_form_container">
                <div className="section_right_wrapper">

                    <div className="section_image_wrapper">
                      <img src={connect_logo} alt="Connect Logo"></img>
                    </div>
                    <div className="section_form">

                      <div className="section_button_wrapper">
                        <Link className="section_button" to={"/" + this.props.section + "/card_management/"}>Autogestión</Link>
                      </div>
                      <div className="section_button_wrapper">
                        <Link className="section_button" to={"/" + this.props.section + "/administrative_procedures/"}>Biblioteca de Trámites Administrativos</Link>
                      </div>

                    </div>
                </div>
              </div>
          </div>
{/*        <div className="modal_pop">
          <Popup
            trigger={<a> <img className="modal_trigger" src={modal_connect}/> </a>}
            modal
            open={this.getPopstate()}
            >
            { close => (
                <div className="modal">
                  <div className="content">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <img src={client_splash}/>
                  </div>
                </div>
            )}
          </Popup>
        </div>*/}
        </div>

    );
    }
}


export default Connect;
