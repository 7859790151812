import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// todo : arreglar warning
class Footer extends Component {
    render() {
        return (
            <footer className='footer_container'>
                <div className='footer_left_container'>
                    <Link className="intro_document" to="/document/privacy_policy/">Aviso de Privacidad</Link>
                    <span className='footer_vertical_line'>|</span>
                    <Link className="intro_document" to="/document/terms_conditions/">Términos y Condiciones</Link>
                </div>
                <div className='footer_right_container'>
                    <a href=".">www.edenredtraining.com</a>
                </div>
            </footer>
        );
    }
}

export default Footer;