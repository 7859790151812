export const SECTION_CODES = {
  despensas: "1",
  ticket_car: "2",
  // ticket_car_vr: "3",
  empresarial: "3",
  fleet: "4",
  connect: "5",
  scann: "5",
  mantenimiento: "6",
};

export const SECTIONS = [
  "despensas",
  "ticket_car",
  "ticket_car_vr",
  "fleet",
  "empresarial",
  "connect",
  "connect_scann",
  "mantenimiento",
];

export const MODULES_CODES = {
  "card_management": "b",
  "practices": "t",
}

export const SECTIONS_URL = {
  "despensas": "https://share.hsforms.com/1wqCDkggkS4y2j6fa2hfPcQaj1b",
  "ticket_car": "https://share.hsforms.com/1Owk3WXrBRiGPNbX3nfGMtgaj1b",
  "ticket_car_vr": "",
  "ecovale": "https://share.hsforms.com/1AZ6gptlcQ6-jLNlbw1hVNwaj1b",
  "connect": "https://share.hsforms.com/1AZ6gptlcQ6-jLNlbw1hVNwaj1b",
  "connect_scann": "https://share.hsforms.com/1AZ6gptlcQ6-jLNlbw1hVNwaj1b",
  "empresarial": "https://share.hsforms.com/1AZ6gptlcQ6-jLNlbw1hVNwaj1b",
  "mantenimiento": "https://share.hsforms.com/1AZ6gptlcQ6-jLNlbw1hVNwaj1b",
}