import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

import Header from '../components/Header';
import Tag from '../components/Tag';

// import etraining from '../images/ic_etraining.svg';
import etraining from '../images/new_logo.png';
import background from '../images/ic_girl.png';

class Thanks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
    }

  }

  render() {

    // if (!this.state.token) {
    //   return (<Redirect to={"/logout/"}/>)
    // }

    return (

      <React.Fragment>

        <Header />

        <div className="thanks">
        
          <div className="thanks_content">

            <Tag color="primary" src={etraining} header={true} etraining={true} />

            <img className="thanks_background" src={background} alt="background" />

            <div className="thanks_form">

              <p className="thanks_title" >Gracias,</p>
              <p className="thanks_subtitle">en breve un asesor te contactará.</p>

            </div>
          
          </div>

        </div>

      </React.Fragment>

    )
    
  }

}

export default Thanks;
