import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { getPublicResources, getPublicSignedResource, setFirstVideo } from '../services/ServerInterface'

import Header from '../components/Header';

class Welcome extends Component {

  constructor(props) {
    super(props);

    this.state = {

      token: localStorage.getItem("token"),
      user: localStorage.getItem("user"),
      profile: localStorage.getItem("profile"),

      firstVideo: undefined,

    }

    getPublicResources().then ((response) => {

      if (response.ok) {

        response.body.forEach(item => {

          if (item.first_video) {
            this.getSignature(item)
            return
          }

        })

      } else if (response.status === 401) {

        this.setState({token: null})

      } else {
        // TODO: Handle server error
      }

    })

    this.getSignature = this.getSignature.bind(this)

  }

  getSignature(resource) {

    let body = { resource_id: resource.resource_id }
    getPublicSignedResource(body).then ((response) => {

      if (response.ok) {

        let signature = response.body

        setFirstVideo(this.state.token).then ((response) => {
          if (response.status === 401) {
            this.setState({token: null})
          }
        })

        this.setState({ url: signature.url })

      } else if (response.status === 401) {

        this.setState({token: null})
        
      }

    }).catch((error) => {
      // TODO: Catch request error
    })

  }

  render() {

    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    return (

      <React.Fragment>

        <Header welcome={true} settings={true} />

        <div className="welcome">

          <div className="welcome_content">

            <div className="welcome_container">
              <iframe title="welcome_video" className="welcome_video" id="welcome_video" src={this.state.url} allowFullScreen={true} nodownload="true" frameBorder="0"></iframe>
            </div>

          </div>

        </div>

      </React.Fragment>

    );
  }
}

export default Welcome;
