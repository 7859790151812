import React from "react";

import { IntercomProvider} from "react-use-intercom";
const INTERCOM_APP_ID = "ipoyh70i";

class Testing extends React.Component {
  render() {
    return <IntercomProvider initializeDelay={3000} appId={INTERCOM_APP_ID} autoBoot autoBootProps={{
      apiBase: "https://api-iam.intercom.io",
      name: "lala", // Full name
      email: "demo@demo.com", // Email address
    }} ></IntercomProvider>;
  }
}

export default Testing;
