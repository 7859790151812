import React, { Component } from 'react';
import feedback_splash from '../images/feedback_splash.png';
import ered_logo from '../images/new_logo.png';
import ReactStars from "react-rating-stars-component";

import '@fortawesome/fontawesome-free/css/all.min.css';

import { sendFeedback } from '../services/ServerInterface';

class Feedback extends Component {
  constructor(props) {

    super(props);
    this.submit_button = React.createRef();
    this.commentbox = React.createRef();

    this.state = {
      token: localStorage.getItem("token"),
      button_ready: false,
      erroclass: "",
      sent: false,
      animation_class: "fadeIn"
    }

    this.rating_score = 0;
    this.rating_ready = false;
    this.comment_ready = false;

    this.enable_button = this.enable_button.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.ratingChanged = this.ratingChanged.bind(this);
    this.try_submit = this.try_submit.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.rating_stars = {
      size: 40,
      count: 5,
      isHalf: false,
      value: 0,
      color: "#c6a4ff",
      activeColor: "#c6a4ff",
      onChange: this.ratingChanged,
      emptyIcon: <i className="ratingstar far fa-star" />,
      filledIcon: <i className="ratingstar fa fa-star" />,

    };

  }


  enable_button(){
    let button_ready = this.rating_ready && this.comment_ready;
    let error_class = this.state.error_class

    if(button_ready){
      error_class = ""
    }

    this.setState({
      button_ready: button_ready,
      error_class: error_class
    })
  }


  ratingChanged = (newRating) => {
    this.rating_ready = true;
    this.rating_score = newRating;
    this.enable_button();
  };


  inputChanged = (e) =>{
    
    this.comment_ready = e.target.textLength >= 6
    this.enable_button();
  };

  try_submit(e){
    if (this.state.button_ready){
      let body = {
        "payload": this.commentbox.current.value,
        "rating": this.rating_score,
        "platform": "web"
      }
      sendFeedback(this.state.token, body).then ((response) => {
        this.setState({
          sent: true
        });
      });

    } else{
      console.log("Erroring out...")
      this.setState({
        error_class: "highlight_error"
      })
    }
  }

  closeModal(){
    this.setState({
      animation_class: "fadeOut"
    })
    this.props.parent_ref.current.close()
  }

  render() {

    if(this.state.sent){
      return (
        <div className="feedback_holder">
          <div className="content">

            <div className="splash_holder feedback_element feedback_done">
              <img id="ered_logo" src={ered_logo} alt="Edenred"/>
            </div>

            <div className="submited_holder feedback_done">
              <p className="submited_flavor">¡Gracias por tus comentarios!</p>
            </div>

            <div className="submit_holder feedback_done">
              <button 
                ref={this.submit_button}
                className='active'
                onClick={this.closeModal}
                >
                  Cerrar
              </button>
            </div> 
            
          </div>
        </div>
      );


    } else{
      return (
        <div className="feedback_holder fadeIn">
          <div className="content">

            <div className="splash_holder feedback_element">
              <img id="ered_logo" src={ered_logo} alt="Edenred"/>
              <img id="opinion" src={feedback_splash} alt="Nos interesa tu opinion" />
            </div>

            <div className="rating_holder feedback_element">
              <ReactStars {...this.rating_stars} />
            </div> 
            
            <div className="comment_holder feedback_element">
              
              <div className="flavor_holder">
                <p
                  className={"on_error " + this.state.error_class}
                  > 
                  Debes de calificar y comentar para continuar.
                </p>
                <p className="details_flavor">Detalles</p>
              </div>

              <textarea 
                className="commentbox"
                ref={this.commentbox}
                placeholder="¿Cómo podemos mejorar nuestros cursos de capacitación?"
                type="text"
                maxLength="1000"
                onChange={this.inputChanged}
                >
              </textarea>
            </div>

            <div className="submit_holder">
              <button 
                ref={this.submit_button}
                className={this.state.button_ready ? 'active' : 'inactive'}
                onClick={this.try_submit}
                >
                  ENVIAR
              </button>
            </div>

          </div>
        </div>
      );
    }
  }

}

export default Feedback;
