import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";

import { getGenericResources } from '../services/ServerInterface'


import empresarial from '../images/ic_empresarial.svg';
import administrative_procedures from '../images/ic_hand_download.png';

// import naturalSort from './naturalSort'


import {TowerDesktop} from '../components/Tower';


class Media extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      section: this.props.match.params.section,
      module: this.props.match.params.module,
      items_beneficios: [],
      items_flota: [],
      items_pago: []
    }

    getGenericResources(this.state.token).then ((response) => {

      if (response.ok) {

        var items_beneficios = [];
        var items_flota = [];
        var items_pago = [];

        response.body.forEach(item => {

            if (item.section === "1"){
              items_beneficios.push(item)


            }

            if (item.section === "2"){
              items_flota.push(item);
            }

            if (item.section === "3"){
              items_pago.push(item);
            }

        })


        // items.sort(naturalSort);
        this.setState({ items_beneficios, items_flota, items_pago})

      } else if (response.status === 401) {

        this.setState({token: null})

      } else {
        // TODO: Handle server error
      }

    })


    this.getBackgroundImage = this.getBackgroundImage.bind(this)
    this.getTagImage = this.getTagImage.bind(this)

  }

  getTagImage() {
      return empresarial
    }

  getBackgroundImage() {
      return administrative_procedures      
    }

  render() {
    if (!this.state.token) {
      return (<Redirect to={"/logout/"}/>)
    }

    let module = "Soluciones"
    
    const items_beneficios = this.state.items_beneficios.map(item => {

      let uuid = item.resource_id
      let name = item.name

      let url = "/generic_resource/" + uuid + "/"
      return (
        <li className="tower_item" key={uuid}>
          <Link className="tower_item_link" to={url}>{name}</Link>
        </li>
      )

    })

    const items_flota = this.state.items_flota.map(item => {

      let uuid = item.resource_id
      let name = item.name

      let url = "/generic_resource/" + uuid + "/"
      return (
        <li className="tower_item" key={uuid}>
          <Link className="tower_item_link" to={url}>{name}</Link>
        </li>
      )

    })

    const items_pago = this.state.items_pago.map(item => {

      let uuid = item.resource_id
      let name = item.name

      let url = "/generic_resource/" + uuid + "/"
      return (
        <li className="tower_item" key={uuid}>
          <Link className="tower_item_link" to={url}>{name}</Link>
        </li>
      )

    })



    return (

      <TowerDesktop
        items_beneficios={items_beneficios}
        items_flota={items_flota}
        items_pago={items_pago}
        module={module}
      />

    );
  }
}

export default Media;
